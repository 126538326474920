import moment from "moment";
import PersonalInfoicons from "../assets/images/PersonalInfoicons.svg";
import ImmigrationTravelicons from "../assets/images/ImmigrationTravelicons.svg";
import Financialicons from "../assets/images/Financialicons.svg";
import Declarationicons from "../assets/images/Declarationicons.svg";
import Employmenticons from "../assets/images/Employmenticons.svg";
import Navicons from "../assets/images/Navicons.svg";
import Eligibility from "../assets/images/Eligibility.svg";
import Miscllaneousicon from "../assets/images/Miscllaneousicon.svg";
import Familymarriageicon from "../assets/images/Familymarriageicon.svg";
import Congratulation from "../assets/images/Congratulation.svg";
import SpecialForms from "../assets/images/SpecialForms.svg";
import Evidence from "../assets/images/Evidence.svg";

import Analytics from "../assets/images/FormIcons/Analytics.svg";
import Bag from "../assets/images/FormIcons/Bag.svg";
import Checklist from "../assets/images/FormIcons/Checklist.svg";
import Commando from "../assets/images/FormIcons/Commando.svg";
import Congratulations from "../assets/images/FormIcons/Congratulations.svg";
import Cup from "../assets/images/FormIcons/Cup.svg";
import Donate from "../assets/images/FormIcons/Donate.svg";
import Family from "../assets/images/FormIcons/Family.svg";
import Find from "../assets/images/FormIcons/Find.svg";
import Headphone from "../assets/images/FormIcons/Headphone.svg";
import Kite from "../assets/images/FormIcons/Kite.svg";
import Magnifier from "../assets/images/FormIcons/Magnifier.svg";
import Medical from "../assets/images/FormIcons/Medical.svg";
import Money from "../assets/images/FormIcons/Money.svg";
import Network from "../assets/images/FormIcons/Network.svg";
import NoPdf from "../assets/images/FormIcons/NoPdf.svg";
import Notepad from "../assets/images/FormIcons/Notepad.svg";
import Passport from "../assets/images/FormIcons/Passport.svg";
import Report from "../assets/images/FormIcons/Report.svg";
import Sad from "../assets/images/FormIcons/Sad.svg";
import Survey from "../assets/images/FormIcons/Survey.svg";
import Task from "../assets/images/FormIcons/Task.svg";
import Team from "../assets/images/FormIcons/Team.svg";
import Toure from "../assets/images/FormIcons/Toure.svg";
import Upload from "../assets/images/FormIcons/Upload.svg";
import Download from "../assets/images/Download.svg";
import Print_Sign from "../assets/images/Print_Sign.svg";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const roleData: any = localStorage.getItem("role");

const hostname = window.CURRENT_HOST;

//It checks a complex condition structure and generates a corresponding boolean expression
export const checkDetailedConditions = (result: any) => {
  let temp = "";
  if (Array.isArray(result)) {
    result.map((row: any) => {
      if (Array.isArray(row)) {
        temp = temp + "(" + checkDetailedConditions(row) + ")";
      } else {
        if (row === "AND") {
          temp = temp + " && ";
        } else if (row === "OR") {
          temp = temp + " || ";
        } else if (row) {
          temp = temp + " true ";
        } else {
          temp = temp + " false ";
        }
      }
    });
  }
  return temp;
};

// This function generates a boolean expression based on the 'data' condition structure and compares it to 'formData'.
// It returns an array of boolean values representing the evaluation of conditions.
export const renderConditions = (data: any, formData: any) => {
  if (data && data.length) {
    return data.map((row: any) => {
      if (Array.isArray(row)) {
        return renderConditions(row, formData);
      } else if (typeof row === "object") {
        let value = formData[row.id];
        let referValue =
          row.value === "date_5y_ago" ? formData["date_5y_ago"] : row.value;
        if (typeof value === "object") {
          if (row.operator == "!=" && referValue == "") {
            return Object.keys(value).length ? true : false;
          }
          value =
            formData[row.id] &&
              formData[row.id]?.includes(row.value?.toString())
              ? row.value
              : "";
        }
        const valueDate = moment(value, "MM/DD/YYYY", true);
        const isReferValueDate = moment(referValue, "MM/DD/YYYY", true);
        if (row.operator == "=" || row.operator == "==") {
          if (row.id === "persona") {
            const role = localStorage.getItem("role");
            return role === referValue;
          }
          if (valueDate.isValid() && isReferValueDate.isValid()) {
            const diff = moment(value).diff(moment(referValue), "days");
            return diff == 0;
          }
          return value == referValue;
        } else if (row.operator == ">") {
          if (valueDate.isValid() && isReferValueDate.isValid()) {
            const diff = moment(value).diff(moment(referValue), "days");
            return diff > 0;
          }
          if (!isNaN(value) && !isNaN(referValue)) {
            return +value > +referValue;
          }
          return false;
        } else if (row.operator == "<") {
          if (valueDate.isValid() && isReferValueDate.isValid()) {
            const diff = moment(value).diff(moment(referValue), "days");
            return diff < 0;
          }
          if (!isNaN(value) && !isNaN(referValue)) {
            return +value < +referValue;
          }
          return false;
        } else if (row.operator == "<=") {
          if (valueDate.isValid() && isReferValueDate.isValid()) {
            const diff = moment(value).diff(moment(referValue), "days");
            return diff <= 0;
          }
          if (!isNaN(value) && !isNaN(referValue)) {
            return +value <= +referValue;
          }
          return false;
        } else if (row.operator == ">=") {
          if (valueDate.isValid() && isReferValueDate.isValid()) {
            const diff = moment(value).diff(moment(referValue), "days");
            return diff >= 0;
          }
          if (!isNaN(value) && !isNaN(referValue)) {
            return +value >= +referValue;
          }
          return false;
        } else if (row.operator == "!=") {
          if (valueDate.isValid() && isReferValueDate.isValid()) {
            const diff = moment(value).diff(moment(referValue), "days");
            return diff != 0;
          }
          const temp =
            value?.toString()?.toLowerCase() !=
            referValue?.toString()?.toLowerCase();

          return temp;
        } else {
          if (valueDate.isValid() && isReferValueDate.isValid()) {
            const diff = moment(value).diff(moment(referValue), "days");
            return diff == 0;
          }
          return (
            value?.toString()?.toLowerCase() ==
            referValue?.toString()?.toLowerCase()
          );
        }
      } else if (typeof row === "string") {
        return row;
      }
    });
  }
};

export const checkDependant = (row: any, formData: any) => {
  try {
    if (!["section", "text"].includes(row?.data?.nodeType?.toLowerCase())) {
      return false;
    }
    let result = "false";
    if (typeof row?.data?.visibility?.condition == "boolean") {
      // console.log("ifffff", row?.data?.nodeTitle);

      result = row?.data?.visibility?.condition?.toString();
    } else if (Array.isArray(row?.data?.visibility?.condition)) {
      // console.log("elseeeeeee",row?.data?.nodeTitle);

      let stringCondition = renderConditions(
        row?.data?.visibility?.condition,
        formData
      );

      result = checkDetailedConditions(stringCondition);
    }
    return eval(result)
      ? row?.data?.visibility?.ifValue
      : row?.data?.visibility?.elseValue;
  } catch (error) {
    logFunction(
      `NODEID(${row.id}) :: There was an error in visibility's condition`
    );
    return false;
  }
};

//It's checks a visibility condition for summary
export const checkDependantSummary = (row: any, formData: any) => {
  try {
    if (!["sectionsummarygroup"].includes(row?.data?.nodeType?.toLowerCase())) {
      return false;
    }
    let result = "false";
    if (typeof row?.data?.visibility?.condition == "boolean") {
      result = row?.data?.visibility?.condition?.toString();
    } else if (Array.isArray(row?.data?.visibility?.condition)) {
      let stringCondition = renderConditions(
        row?.data?.visibility?.condition,
        formData
      );
      result = checkDetailedConditions(stringCondition);
    }

    return eval(result)
      ? row?.data?.visibility?.ifValue
      : row?.data?.visibility?.elseValue;
  } catch (error) {
    logFunction(
      `NODEID(${row.id}) :: There was an error in visibility's condition`
    );
    return false;
  }
};

//It checks a visibilty condition for Quesiton group and returns the boolean value from the JSON(ifValue/elseValue)
export const checkDependantGroup = (row: any, formData: any) => {
  try {
    if (!Object.keys(row?.data?.visibility || {}).length) {
      return true;
    }

    let result = "false";
    if (typeof row?.data?.visibility?.condition == "boolean") {
      result = row?.data?.visibility?.condition?.toString();
    } else if (Array.isArray(row?.data?.visibility?.condition)) {
      let stringCondition = renderConditions(
        row?.data?.visibility?.condition,
        formData
      );
      result = checkDetailedConditions(stringCondition);
    }

    return eval(result)
      ? row?.data?.visibility?.ifValue
      : row?.data?.visibility?.elseValue;
  } catch (error) {
    logFunction(
      `NODEID(${row.id}) :: There was an error in visibility's condition`
    );
    return false;
  }
};

//It checks a visibility condition and returns the boolean value from the JSON(ifValue/elseValue)
export const checkDependantQuestion = (row: any, formData: any) => {
  try {
    if (!Object.keys(row?.data?.visibility || {}).length) {
      return true;
    }

    let result = "false";
    if (typeof row?.data?.visibility?.condition == "boolean") {
      result = row?.data?.visibility?.condition?.toString();
      // return row?.data?.visibility?.condition
      //   ? row?.data?.visibility?.ifValue
      //   : row?.data?.visibility?.elseValue;
    } else if (Array.isArray(row?.data?.visibility?.condition)) {
      let stringCondition = renderConditions(
        row?.data?.visibility?.condition,
        formData
      );

      result = checkDetailedConditions(stringCondition);
    }
    return eval(result)
      ? row?.data?.visibility?.ifValue
      : row?.data?.visibility?.elseValue;
  } catch (error) {
    logFunction(
      `NODEID(${row.id}) :: There was an error in visibility's condition`
    );
    return false;
  }
};

//It checks a required condition and returns the boolean value from the JSON(ifValue/elseValue)
export const checkDependantQuestionRequired = (row: any, formData: any) => {
  try {
    if (!Object.keys(row?.data?.required || {}).length) {
      return false;
    }
    let result = "false";
    if (typeof row?.data?.required?.condition == "boolean") {
      result = row?.data?.required?.condition?.toString();
      // return row?.data?.required?.condition;
    } else if (Array.isArray(row?.data?.required?.condition)) {
      let stringCondition = renderConditions(
        row?.data?.required?.condition,
        formData
      );
      result = checkDetailedConditions(stringCondition);
    }

    return eval(result)
      ? row?.data?.required?.ifValue
      : row?.data?.required?.elseValue;
  } catch (error) {
    logFunction(
      `NODEID(${row.id}) :: There was an error in required's condition`
    );
    return false;
  }
};

// It checks a condition and returns the title value from JSON(ifValue/elseValue).
// If there are no specified title conditions available, it will return the title from row?.data?.title.
export const checkDependantQuestionTitle = (row: any, formData: any) => {
  try {
    if (!Object.keys(row?.data?.properties?.title || {}).length) {
      return row?.data?.nodeTitle;
    }

    let result = "false";
    let ifValueTitle = row?.data?.properties?.title?.ifValue || "";
    let elseValueTitle = row?.data?.properties?.title?.elseValue || "";

    if (typeof row?.data?.properties?.title?.condition == "boolean") {
      result = row?.data?.properties?.title?.condition?.toString();
    } else if (Array.isArray(row?.data?.properties?.title?.condition)) {
      //Check if the condition is Array
      let stringCondition = renderConditions(
        row?.data?.properties?.title?.condition,
        formData
      );
      result = checkDetailedConditions(stringCondition);
    }

    //Replace the substitutions if available
    if (
      row?.data?.properties?.title?.substitutions &&
      Object.keys(row?.data?.properties?.title?.substitutions).length
    ) {
      ifValueTitle = replaceSubstitutions(
        ifValueTitle,
        row?.data?.properties?.title?.substitutions,
        formData
      );
      elseValueTitle = replaceSubstitutions(
        elseValueTitle,
        row?.data?.properties?.title?.substitutions,
        formData
      );
    }

    //Replace the Links if available 15-11-2023
    if (
      row?.data?.properties?.title?.links &&
      Object.keys(row?.data?.properties?.title?.links).length
    ) {
      ifValueTitle = replaceLinksIntoTag(
        ifValueTitle,
        row?.data?.properties?.title?.links,
        row
      );
      elseValueTitle = replaceLinksIntoTag(
        elseValueTitle,
        row?.data?.properties?.title?.links,
        row
      );
    }

    return eval(result) ? ifValueTitle : elseValueTitle;
  } catch (error) {
    logFunction(`NODEID(${row.id}) :: There was an error in title's condition`);
    return row?.data?.nodeTitle;
  }
};

// It checks a condition and returns the editable value from JSON(ifValue/elseValue).
// If There is nothing any editable condition available then It's return true
export const checkDependantQuestionEditable = (row: any, formData: any) => {
  try {
    if (!Object.keys(row?.data?.properties?.editable || {}).length) {
      return true;
    }

    let result = "false";
    if (typeof row?.data?.properties?.editable?.condition == "boolean") {
      result = row?.data?.properties?.editable?.condition?.toString();
    } else if (Array.isArray(row?.data?.properties?.editable?.condition)) {
      let stringCondition = renderConditions(
        row?.data?.properties?.editable?.condition,
        formData
      );
      result = checkDetailedConditions(stringCondition);
    }

    return eval(result)
      ? row?.data?.properties?.editable?.ifValue
      : row?.data?.properties?.editable?.elseValue;
  } catch (error) {
    logFunction(
      `NODEID(${row.id}) :: There was an error in editable's condition`
    );
    return true;
  }
};

// It checks a condition and returns the editable value from JSON(ifValue/elseValue).
// If There is nothing any editable condition available then It's return true
export const checkDependantQuestionDefaultValue = (row: any, formData: any) => {
  try {
    if (!Object.keys(row?.data?.properties?.defaultValue || {}).length) {
      return "";
    }

    let result = "false";
    let ifDefaultValue = row?.data?.properties?.defaultValue?.ifValue || "";
    let elseDefaultValue = row?.data?.properties?.defaultValue?.elseValue || "";
    if (typeof row?.data?.properties?.defaultValue?.condition == "boolean") {
      result = row?.data?.properties?.defaultValue?.condition?.toString();
    } else if (Array.isArray(row?.data?.properties?.defaultValue?.condition)) {
      let stringCondition = renderConditions(
        row?.data?.properties?.defaultValue?.condition,
        formData
      );

      result = checkDetailedConditions(stringCondition);
    }

    if (
      row?.data?.properties?.defaultValue?.substitutions &&
      Object.keys(row?.data?.properties?.defaultValue?.substitutions).length
    ) {
      ifDefaultValue = replaceSubstitutions(
        ifDefaultValue,
        row?.data?.properties?.defaultValue?.substitutions,
        formData
      );

      elseDefaultValue = replaceSubstitutions(
        elseDefaultValue,
        row?.data?.properties?.defaultValue?.substitutions,
        formData
      );
    }

    return eval(result) ? ifDefaultValue : elseDefaultValue;
  } catch (error) {
    logFunction(
      `NODEID(${row.id}) :: There was an error in defaultValue's condition`
    );
    return "";
  }
};

// This function is used for organize the question to insert into GRID Container for style attr.
export function organizeQuestionsIntoRows(questions: any[]) {
  const rows: any = {};
  let newRow = 0;

  // Iterate through the questions and group them by row
  questions.forEach((question: any, index: any) => {
    const row = question?.data?.style?.row
      ? `row_${question?.data?.style?.row}`
      : `newRow_${++newRow}`; // If row is undefined, consider it a new row

    if (!rows[row]) {
      rows[row] = [];
    }

    rows[row].push(question);
  });

  // Convert the rows object into an array
  const rowArray = Object.values(rows);

  return rowArray;
}

//To orgranize question into their respective parent
export function structureArray(arr: any) {
  const structuredArr: any[] = [];
  const itemMap: any = {};

  // Create a mapping of items by their ID
  arr?.length &&
    arr?.forEach((item: any) => {
      item.childItem = [];
      itemMap[item.id] = item;
    });

  // Organize items into their respective parent
  arr?.length &&
    arr?.forEach((item: any) => {
      if (item?.data?.nodeType?.toLowerCase() != "question") {
        if (item.parentNode) {
          const parentItem = itemMap[item.parentNode];
          if (parentItem) {
            parentItem.childItem.push(item);
          }
        } else {
          structuredArr.push(item);
        }
      }
    });

  return structuredArr;
}

//To orgranize question into their respective parent
export function structureArrayQuestion(arr: any) {
  const structuredArr: any[] = [];
  const itemMap: any = {};

  // Create a mapping of items by their ID
  arr?.length &&
    arr?.forEach((item: any) => {
      item.childItem = [];
      itemMap[item.id] = item;
    });

  // Organize items into their respective parent
  arr?.length &&
    arr?.forEach((item: any) => {
      if (
        item.parentNode &&
        item?.data?.nodeType?.toLowerCase() === "question"
      ) {
        const parentItem = getSection(item?.parentNode, itemMap);
        if (
          parentItem &&
          parentItem?.data?.nodeType?.toLowerCase() === "section"
        ) {
          parentItem.childItem.push(item);
        }
      } else if (item?.data?.nodeType?.toLowerCase() === "section") {
        structuredArr.push(item);
      }
    });

  return structuredArr;
}

function getSection(parentNode: any, itemMap: any): any {
  let parentItem = itemMap[parentNode];

  // Check for a valid parentItem and nodeType
  if (
    parentItem &&
    parentItem?.data &&
    parentItem?.data.nodeType &&
    parentItem?.data.nodeType.toLowerCase() === "section"
  ) {
    return parentItem;
  }

  // Check for a valid parentNode to avoid infinite recursion
  if (parentItem && parentItem?.parentNode) {
    return getSection(parentItem?.parentNode, itemMap);
  }

  return null;
}

// To repalce the substitutions in the title of question
function replaceSubstitutions(
  inputString: string,
  substitutions: any,
  formData: any
) {
  // Regular expression to match placeholders like [placeholder]
  const placeholderRegex = /\[([^\]]+)]/g;

  // Use replace method with a callback function to replace placeholders
  const replacedString = inputString.replace(placeholderRegex, (match, key) => {
    // Check if the key exists in the substitutions object and formData

    if (
      substitutions.hasOwnProperty(key) &&
      formData.hasOwnProperty(substitutions[key])
    ) {
      return formData[substitutions[key]];
    }
    // If the key does not exist, leave the placeholder as is
    return "";
  });

  return replacedString;
}

// To repalce the links in the title of question 15-11-2023
function replaceLinksIntoTag(
  inputString: string,
  linksData: any,
  mainData: any
) {
  // Regular expression to match placeholders like [placeholder]
  const placeholderRegex = /\{\{([^}]+)}}/g;

  let result = "";

  // Use replace method with a callback function to replace placeholders
  const replacedString = inputString.replace(placeholderRegex, (match, key) => {
    // Check if the key exists in the linksData object
    if (linksData.hasOwnProperty(key)) {
      const linkText = linksData[key]?.text;
      if (linksData[key]?.id) {
        result += `<button key="${mainData?.id}" class="internal-pageLink" linkIds=${linksData[key]?.id}>${linkText}</button>`;
      } else {
        result += `<a target="_blank" class="internal-pageURL-Link" href=${linksData[key]?.url}>${linkText}</a>`;
      }
    }
    return result || match;
  });

  return replacedString;
}

//Convert the payload
export function convertPayload(inputPayload: any) {
  const inputData = inputPayload.data[0];
  const outputData = [];

  for (const key in inputData) {
    const value = inputData[key];
    if (Array.isArray(value)) {
      for (const val of value) {
        const newObj: any = {};
        newObj[key] = val;
        outputData.push(newObj);
      }
    } else {
      const newObj: any = {};
      newObj[key] = value;
      outputData.push(newObj);
    }
  }

  const outputPayload = { data: outputData };

  return outputPayload;
}

export function convertArrayToFormat(inputArray: any) {
  const data: any = [{}];

  for (const obj of inputArray) {
    for (const key in obj) {
      if (!data[0].hasOwnProperty(key)) {
        data[0][key] = obj[key];
      } else {
        if (!Array.isArray(data[0][key])) {
          data[0][key] = [data[0][key]];
        }
        data[0][key].push(obj[key]);
      }
    }
  }

  return data;
}

export function convertDocumentTypeArr(data: any) {
  let result = [];

  // Create an object to store grouped data by "category"
  let groupedData: any = {};

  data.forEach((item: any) => {
    const { userId, category, status, ...documentData } = item;

    if (!groupedData[category]) {
      groupedData[category] = {
        userId,
        category,
        status,
        documentType: [],
      };
    }

    groupedData[category].documentType.push(documentData);
  });

  // Convert the grouped data object to an array
  for (const key in groupedData) {
    if (groupedData.hasOwnProperty(key)) {
      result.push(groupedData[key]);
    }
  }

  return result;
}

//api url
export function getApiUrl() {
  console.log("Hostname ===>", hostname);

  return window.API_URL;
}

export function getFrontBaseUrl() {
  if (hostname.includes("localhost")) {
    return "http://localhost:3000/home/";
  } else if (hostname.includes("-dev")) {
    return "https://my-dev.usapath.com/home/";
  } else {
    return "https://my.usapath.com/home/";
  }
}

//To generate the year range
export function generateYearRange() {
  const currentYear = moment().year();
  const startYear = 1950;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}

//Convert into camelcase
export function toCamelCase(str: any) {
  if (!str) {
    return "";
  }
  return str.toLowerCase().replace(/\b\w/g, (s: any) => s.toUpperCase());
}

//Convert into camelcase
export function toUpperCase(str: any) {
  if (!str) {
    return "";
  }
  return str.toUpperCase();
}

// Return Current Object
export function currentIdObjects(givenId: any, datas: any) {
  let currentDataList: any = {};
  let questionGroupData: any = {};
  let findDatas = datas?.nodes?.find((val: any) => val?.id == givenId);
  if (findDatas?.data?.nodeType?.toLowerCase() == "questiongroup") {
    questionGroupData = findDatas;
  } else if (findDatas?.data?.nodeType?.toLowerCase() == "subquestiongroup") {
    let qgData = datas?.nodes?.find(
      (val: any) => val?.id == findDatas?.parentNode
    );
    questionGroupData = qgData;
  } else if (findDatas?.data?.nodeType?.toLowerCase() == "question") {
    let tempData = datas?.nodes?.find(
      (val: any) => val?.id == findDatas?.parentNode
    );
    if (tempData?.data?.nodeType?.toLowerCase() == "questiongroup") {
      questionGroupData = tempData;
    } else if (tempData?.data?.nodeType?.toLowerCase() == "subquestiongroup") {
      let sQgData = datas?.nodes?.find(
        (val: any) => val?.id == tempData?.parentNode
      );
      questionGroupData = sQgData;
    }
  }
  let sectionSummaryData = datas?.nodes?.find(
    (val: any) => val?.id == questionGroupData?.parentNode
  );
  let sectionAllData = datas?.nodes?.find(
    (val: any) => val?.id == sectionSummaryData?.parentNode
  );
  currentDataList.currentQuestionGroupId = questionGroupData?.id;
  currentDataList.currentGroupSummaryId = sectionSummaryData?.id;
  currentDataList.currentSectionId = sectionAllData?.id;
  return currentDataList;
}

// Return Current Summary Object
export function currentIdObjectsSummary(givenId: any, datas: any) {
  let currentDataList: any = {};
  let summaryQuestion: any = {};
  let sectionMainData: any = {};
  let findDatas = datas?.nodes?.find((val: any) => val?.id == givenId);
  let findDatasFilter = datas?.nodes?.find(
    (val: any) =>
      val?.parentNode == givenId && val?.data?.nodeType === "questionGroup"
  );

  if (findDatas?.data?.nodeType?.toLowerCase() === "sectionsummarygroup") {
    summaryQuestion = findDatas;
    let sectionData = datas?.nodes?.find(
      (val: any) => val?.id == findDatas?.parentNode
    );
    sectionMainData = sectionData;
  }
  currentDataList.currentQuestionGroupId = findDatasFilter?.id;
  currentDataList.currentGroupSummaryId = summaryQuestion?.id;
  currentDataList.currentSectionId = sectionMainData?.id;
  return currentDataList;
}

// This function generates a boolean expression based on the 'data' condition structure and compares it to 'formData'.
// It returns an array of boolean values representing the evaluation of conditions.
export const checkDefaultId = (data: any, id: any) => {
  return data.map((row: any) => {
    if (Array.isArray(row)) {
      return checkDefaultId(row, id);
    } else if (typeof row === "object") {
      return row?.id == id;
    } else {
      return false;
    }
  });
};

// Retrun Images 10-11-2023
export function displayIcon(icons: any) {
  let image: any = "";
  if (icons === "personal") {
    return PersonalInfoicons;
  } else if (icons === "immigration") {
    return ImmigrationTravelicons;
  } else if (icons === "financials") {
    return Financialicons;
  } else if (icons === "disclaimer") {
    return Declarationicons;
  } else if (icons === "employment") {
    return Employmenticons;
  } else if (icons === "eligibility") {
    return Eligibility;
  } else if (icons === "miscllaneous") {
    return Miscllaneousicon;
  } else if (icons === "familymarriage") {
    return Familymarriageicon;
  } else if (icons === "congratulation") {
    return Congratulation;
  } else if (icons === "Evidence") {
    return Evidence;
  } else if (icons === "medical") {
    return SpecialForms;
  } else if (icons === "Analytics") {
    return Analytics;
  } else if (icons === "Bag") {
    return Bag;
  } else if (icons === "Checklist") {
    return Checklist;
  } else if (icons === "Commando") {
    return Commando;
  } else if (icons === "Congratulations") {
    return Congratulations;
  } else if (icons === "Cup") {
    return Cup;
  } else if (icons === "Donate") {
    return Donate;
  } else if (icons === "Family") {
    return Family;
  } else if (icons === "Find") {
    return Find;
  } else if (icons === "Headphone") {
    return Headphone;
  } else if (icons === "Kite") {
    return Kite;
  } else if (icons === "Magnifier") {
    return Magnifier;
  } else if (icons === "Medical") {
    return Medical;
  } else if (icons === "Money") {
    return Money;
  } else if (icons === "Network") {
    return Network;
  } else if (icons === "NoPdf") {
    return NoPdf;
  } else if (icons === "Notepad") {
    return Notepad;
  } else if (icons === "Passport") {
    return Passport;
  } else if (icons === "Report") {
    return Report;
  } else if (icons === "Sad") {
    return Sad;
  } else if (icons === "Survey") {
    return Survey;
  } else if (icons === "Task") {
    return Task;
  } else if (icons === "Team") {
    return Team;
  } else if (icons === "Toure") {
    return Toure;
  } else if (icons === "Upload") {
    return Upload;
  } else if (icons === "Pay") {
    return Download;
  } else if (icons === "Print") {
    return Print_Sign;
  }
  return Navicons;
}

export function shouldThirdPartyIntegration() {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return false;
  } else if (hostname.includes("-dev")) {
    return true;
  } else {
    return true;
  }
}

export function anonymousUser() {
  let token = localStorage.getItem("accessToken");

  if (token) {
    return false;
  } else {
    return true;
  }
}

// Generate a random GUID
export function generateGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// To save some of the data to mixpanel
export function storeSanitizedData(data: Record<string, any>, auth0ID: any) {
  const approvedKeys = ["bonafide_score", "income_limit_flag"];

  if (!(window as any).sanitizedData) {
    (window as any).sanitizedData = {};
  }

  if (data) {
    approvedKeys.forEach((key) => {
      if (key in data) {
        (window as any).sanitizedData[key] = data[key];
      }
    });
  }
}

// To send all the section data to App Insights
export function setAppInsightsData(title: string, authConfig: any) {
  if (authConfig) {
    if (authConfig?.appInsightsConnectionString) {
      const browserHistory = createBrowserHistory({ basename: "" } as any);
      var reactPlugin = new ReactPlugin();
      const clickPluginInstance = new ClickAnalyticsPlugin();
      const clickPluginConfig = {
        autoCapture: true,
      };

      // Application Insights Configuration
      const configObj = {
        connectionString: authConfig?.appInsightsConnectionString,
        extensions: [clickPluginInstance, reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
          [clickPluginInstance.identifier]: clickPluginConfig,
        },
        enableAutoRouteTracking: true,
      };

      const appInsights = new ApplicationInsights({ config: configObj });

      const telemetry = {
        uri: window.location.href,
        name: title,
        properties: {
          pageTitle: title,
        },
        measurements: {
          loadTime: 1000, // 1 seconds
        },
      };
      logFunction("telemetry" + telemetry);

      // appInsights.trackPageView(telemetry);
    }
  }
}

// To log the responses
export function logFunction(loggedData: any) {
  return;
  // console.log("Logging data for you: ", loggedData);
}

// To log the responses error
export function logErrorFunction(loggedData: any) {
  console.error("Logging error data for you: ", loggedData);
}

export function billingEntityType() {
  let isIframe = window.self !== window.top;

  if (isIframe) {
    if (roleData === "lawyer") {
      return "lawyer";
    } else {
      return "client";
    }
  } else {
    return "usapath";
  }
}
