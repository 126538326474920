import { useEffect, useState, useRef } from "react";
import { Box, Grid, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Stepper, Step, Button } from "@material-tailwind/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import TextComponent from "../TextComponent";
import DropdownField from "../Dropdown";
import ListTextComponent from "../ListTextComponent";
import InputFiled from "../InputFiled";
import MultiInputField from "../MultiInputField";
import DatePickerComponent from "../datePicker";
import MultiSelection from "../MultiSelection";
import BooleanInput from "../booleanInput";
import ValidateFormJson from "./validateFormJson";
import {
  anonymousUser,
  checkDefaultId,
  checkDependant,
  checkDependantGroup,
  checkDependantQuestion,
  checkDependantQuestionDefaultValue,
  checkDependantQuestionEditable,
  checkDependantQuestionTitle,
  checkDependantSummary,
  currentIdObjects,
  currentIdObjectsSummary,
  generateGuid,
  logFunction,
  organizeQuestionsIntoRows,
  setAppInsightsData,
} from "../../helper/service";
import { calculateRules } from "../../helper/rules-calculator";
import {
  calculateIncomeFlag,
  calculateIncomeFlagV2,
} from "../../helper/income-limit-calculator";
import { calculateBonafideMarriageScore } from "../../helper/bonafide-marriage-calculator";
import SummaryScreen from "./summaryScreen";
import FirstScreen from "./firstScreen";
import PayAndDownload from "../../pages/payAndDownload";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { notificationFail } from "../../store/slices/notificationSlice";
import {
  setCurrentFaqsData,
  setCurrentFormData,
  setCurrentQuestionList,
  setFaqDialog,
  setPreviewDialog,
  setUiFormPayload,
} from "../../store/slices/flowFormSlice";
import {
  getSetSectionId,
  addUserForm,
  replenish,
} from "../../store/thunk/formThunk";
import {
  getProgressData,
  getSummaryData,
} from "../../store/thunk/documentThunk";
import MessageIcon from "../../icon/MessageIcon";
import RefreshIcon from "../../icon/RefreshIcon";
import EyeIcon from "../../icon/EyeIcon";
import ArrowLeft from "../../assets/images/Arrow -left.svg";
import ArrowRight from "../../assets/images/Arrow -Right.png";
import Logger from "../../helper/loggerfunction";

initializeIcons();

interface MessageData {
  authToken?: string;
  user?: {
    id?: any;
    email?: string;
    name?: string;
    phoneNumber?: string;
    userPersona?: string;
  };
  role?: string;
  lawyerData?: any;
}

const expectedCalculationFields = {
  FINF814: "",
  FINF815: "",
  FINF816: "",
  FINF817: "",
  FINF825: "",
  FINF826: "",
  FINF827: "",
  FINF828: "",
  FINF811: "",
  FINF812: "",
  IT381: "",
  IT382: "",
  IT382a: "",
  PI351: "",
  FINF811a: "",
  FINF812b: "",
  FINF812e: "",
};

let clickCount = 0;

function StepperComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const hidden = useMediaQuery("(min-width:900px)");
  const loggerRef = useRef<Logger | null>(null);

  const sectionId = useAppSelector((state: any) => state.formReducer.sectionid);
  const [userRole, setUserRole] = useState<string | null>(null);

  const { uiIncomeLimit } = useAppSelector((state: any) => state.authReducer);

  const loading = useAppSelector(
    (state: any) => state.notificationReducer.loading
  );
  const isFaqDialog = useAppSelector(
    (state: any) => state.formReducer.faqDialog
  );
  const previewDialog = useAppSelector(
    (state: any) => state.formReducer.previewDialog
  );

  const { getSignleFormData, uiFormPayload } = useAppSelector(
    (state: any) => state?.formReducer
  );

  const { authConfig } = useAppSelector((state: any) => state.authReducer);

  let tempNodes: any = [];
  let preservValue: any = {};

  const [waitForRender, setWaitForRender] = useState<any>(false);
  const [debunceCount, setdebounceCount] = useState<any>(0);
  const [activeSection, setActiveSection] = useState(0);
  const [activeSummary, setActiveSummary] = useState(0);
  const [activeSummaryId, setActiveSummaryId] = useState(0);
  const [shouldContinue, setShouldContinue] = useState(true);
  const [isFirstSection, setIsFirstSection] = useState(false);
  const [isLastSection, setIsLastSection] = useState(false);
  const [isFirstSummary, setIsFirstSummary] = useState(false);
  const [isLastSummary, setIsLastSummary] = useState(false);
  const [summaryList, setSummaryList] = useState<any>([]);
  const [questionGroups, setQuestionGroups] = useState<any>([]);
  const [activeGroup, setActiveGroup] = useState("");
  const [currentQuestions, setCurrentQuestions] = useState<any>([]);

  const dispatch: any = useAppDispatch();
  const [errors, setErrors] = useState<any>({});
  let [formData, setFormData] = useState<any>({});
  const [defaultValues, setDefaultValues] = useState<any>({});
  const [uiNodeList, setUiNodeList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHandlePrev, setIsHandlePrev] = useState(false);
  const [openFAQ, setIsOpenFAQ] = useState<any>(false);
  const [openPreview, setIsOpenPreview] = useState<any>(false);
  const [locked, setLocked] = useState<any>(false);
  const [showRefresh, setShowRefresh] = useState<any>(false);
  const [isIframe, setIsIframe] = useState<boolean>(window.self !== window.top);
  // const [isDeadlock, setIsDeadlock] = useState(false);
  // const [nextGroupButton, setNextGroupButton] = useState("");
  // const [prevGroupButton, setPrevGroupButton] = useState("");
  // const [prevSummaryButton, setPrevSummaryButton] = useState("");
  // const [nextSummaryButton, setNextSummaryButton] = useState("");
  // const [prevSectionButton, setPrevSectionButton] = useState("");
  // const [nextSectionButton, setNextSectionButton] = useState("");
  // const [isDeadlockSection, setIsDeadlockSection] = useState(false);

  let linkIDs = document.querySelectorAll(".internal-pageLink");

  const unSaveData = sessionStorage.getItem("unSavedFormValues")
    ? JSON.parse(sessionStorage.getItem("unSavedFormValues") || "{}")
    : null;

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);
  const lockedApp = localStorage.getItem("lockedApp");

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    loggerRef.current = new Logger();

    return () => {
      // Clear the timer if Logger instance exists
      if (loggerRef.current && loggerRef.current["timer"]) {
        clearTimeout(loggerRef.current["timer"]);
      }
    };
  }, []);

  useEffect(() => {
    // Check if app is being loaded in an iframe
    if (window.self !== window.top) {
      window.parent.postMessage({ type: "iframeLoaded" }, "*");

      const handleMessage = (event: MessageEvent<MessageData>) => {
        const { role } = event.data;
        if (role) {
          setUserRole(role);
        }
      };

      window.addEventListener("message", handleMessage);

      return () => {
        window.removeEventListener("message", handleMessage);
      };
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", appHeight);
    appHeight();
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!["/home"].includes(decodeURI(location.pathname))) {
      document.body.classList.add("bottom_intercom");
    }

    return () => {
      document.body.classList.remove("bottom_intercom");
    };
  }, [location.pathname]);

  useEffect(() => {
    // dispatch(replenish());
    const unSavedFormValues = sessionStorage.getItem("unSavedFormValues")
      ? JSON.parse(sessionStorage.getItem("unSavedFormValues") || "{}")
      : null;

    if (unSavedFormValues?.ui_form_lock_state) {
      setLocked(unSavedFormValues?.ui_form_lock_state);
      localStorage.setItem("lockedApp", unSavedFormValues?.ui_form_lock_state);
    } else {
      setLocked(false);
      localStorage.setItem("lockedApp", "false");
    }
  }, [sessionStorage]);

  // Change Logic for Click Event 16-11-2023
  useEffect(() => {
    if (!!linkIDs) {
      linkIDs.forEach(function (currentLink: any) {
        currentLink.addEventListener("click", function (e: any) {
          if (
            currentLink &&
            currentLink.classList.contains("internal-pageLink")
          ) {
            onClickLink(e, currentLink.getAttribute("linkIds"));
          }
        });
      });
    }
  }, [linkIDs]);

  useEffect(() => {
    setFormData({ ...formData, ...uiFormPayload });

    if (
      uiFormPayload &&
      Object.keys(formData).length &&
      !uiFormPayload?.date_5y_ago
    ) {
      handleFieldChange(
        "date_5y_ago",
        moment().subtract(5, "year").format("MM/DD/yyyy")
      );
    }
  }, [uiFormPayload]);

  useEffect(() => {
    setIsOpenFAQ(isFaqDialog);
  }, [isFaqDialog]);

  useEffect(() => {
    setIsOpenPreview(previewDialog);
  }, [previewDialog]);

  useEffect(() => {
    const fetchData = async () => {
      const flowData = JSON.parse(getSignleFormData?.flowJson || "{}");
      const isFlowJsonEmpty = Object.keys(flowData).length === 0;

      if (getSignleFormData?.flowJson && !isFlowJsonEmpty) {
        // const largeFormData = await largestForm(getSignleFormData);
        let uiJson = JSON.parse(getSignleFormData.uiJson);
        let flowJson = JSON.parse(getSignleFormData.flowJson);

        // const questionArr = uiJson.filter(
        //   (val: any) => val.data.nodeType == "question"
        // );
        // setQuestionList(questionArr);

        const questionArr1 = flowJson?.nodes
          ?.map((flowVal: any) => {
            const tempObj = uiJson.find((x: any) => flowVal?.id === x?.id);
            if (tempObj) {
              return { ...flowVal, ...tempObj };
            }
            return null; // Return null for objects that couldn't be merged
          })
          .filter((obj: any) => obj !== null) // Filter out null objects
          .filter((y: any) => y?.data?.nodeType == "question");

        const tempUiJson = flowJson?.nodes?.flatMap((flowJsonData: any) =>
          uiJson
            ?.filter(
              (getJsonDataItem: any) => flowJsonData.id === getJsonDataItem.id
            )
            .map((matchingItem: any) => ({ ...matchingItem, ...flowJsonData }))
        );

        setQuestionList(questionArr1);

        setUiNodeList(tempUiJson);

        // setDeadLock(uiData.filter((val: any) => val.data.nodeType?.toLowerCase() === "text"));
      } else {
        setQuestionList([]);
        setUiNodeList([]);
      }
    };
    fetchData();
  }, [getSignleFormData]);

  useEffect(() => {
    // if (deadLock && deadLock.length) {
    //   const obj = deadLock.map((obj: any) => checkDependantGroup(obj))[0];
    //   setCurrDeadLock(obj);
    // }
    // let result = checkDetailedConditions(stringCondition);

    if (Object.keys(formData).length > 0) {
      dispatch(setCurrentFormData(formData));
      if (
        !anonymousUser() &&
        sessionStorage.getItem("Usapath-Emulate-UserScope") !== "Readonly"
      ) {
        sessionStorage.setItem("unSavedFormValues", JSON.stringify(formData));
      }
      // old logic
      // !anonymousUser() &&
      //   sessionStorage.setItem("unSavedFormValues", JSON.stringify(formData));
      setGroupList();
    }
  }, [formData]);

  useEffect(() => {
    if (typeof sectionId?.index !== "undefined" && sectionId?.sectionNodeData) {
      setActiveSection(sectionId?.index);

      setActiveSummary(sectionId?.summaryIndex || 0);
      let summaryList = sectionId?.sectionNodeData?.find(
        (val: any) => val.id === sectionId.id
      );

      const sectionData = sectionId?.sectionNodeData?.filter(
        (val: any) => val.id === sectionId.id
      )?.[0];

      const summaryData = sectionData?.childItem
        ?.filter((val: any) => checkDependantSummary(val, formData))
        ?.map((val: any) => val.id);

      setSummaryList(summaryData);

      setActiveSummaryId(
        sectionId?.summaryId ? sectionId?.summaryId : summaryList?.children?.[0]
      );
      let groupList = sectionId?.sectionNodeData?.find(
        (val: any) => val.id === sectionId?.summaryId
      );

      setActiveGroup(
        sectionId?.questionGroupId
          ? sectionId?.questionGroupId
          : groupList?.children?.[0]
      );

      setGroupList();

      // setIsDeadlockSection(summaryList?.data?.nodeType?.toLowerCase() === "text");
      currentQuestions.splice(0, currentQuestions.length);

      setTimeout(() => {
        setWaitForRender(false);
      }, 100);
    }
  }, [sectionId]);

  useEffect(() => {
    dispatch(setCurrentQuestionList([...currentQuestions]));
    currentQuestions.map((val: any) => {
      let defaultValue = checkDependantQuestionDefaultValue(val, formData);
      const unSavedFormValues = JSON.parse(
        sessionStorage.getItem("unSavedFormValues") || "{}"
      );

      if (val.id && !unSavedFormValues.hasOwnProperty(val.id) && defaultValue) {
        setFormData({
          ...formData,
          [val.id]: defaultValue,
        });
      }
    });
  }, [currentQuestions]);

  useEffect(() => {
    if (activeGroup) {
      const nodesData = JSON.parse(getSignleFormData?.flowJson || "{}");
      let questionGroup = nodesData?.nodes?.filter(
        (val: any) => val?.id == activeGroup || val?.parentNode == activeGroup
      );

      // Check if anyof the value have nodeType = "subquestiongroup"
      const subquestiongroup = questionGroup?.filter(
        (val: any) => val?.data?.nodeType == "subquestiongroup"
      );
      if (subquestiongroup?.length > 0) {
        const question = nodesData?.nodes?.filter((val: any) => {
          return subquestiongroup?.some(
            (sqg: any) => sqg?.id == val?.parentNode
          );
        });
        questionGroup = [...questionGroup, ...question];
      }

      const faqsData = questionGroup
        ?.filter((group: any) => group?.data?.faqIds !== undefined)
        ?.map((obj: any) => obj?.data?.faqIds);
      const uniqueFlattenedArr = Array.from(
        new Set(
          faqsData
            ?.flatMap((res: any) => res?.split(","))
            ?.map((value: any) => parseInt(value.trim()))
            ?.filter((value: any) => !isNaN(value))
        )
      );

      dispatch(setCurrentFaqsData(uniqueFlattenedArr || []));
    }
  }, [activeGroup]);

  useEffect(() => {
    if (openFAQ || openPreview) {
      document.body.classList.add("abc");
    } else {
      document.body.classList.remove("abc");
    }
  }, [openFAQ, openPreview]);

  // const setAppInsights = (title: string) => {
  //   if (authConfig) {
  //     setAppInsightsData(title, authConfig);
  //   }
  // };

  const toggleFaqDrawer = () => {
    if (!openFAQ) {
      saveUpdatedField(false);
    }
    // setAppInsights("FAQ");

    dispatch(setFaqDialog(!openFAQ));
    dispatch(setPreviewDialog(false));
  };

  const togglePreviewDrawer = () => {
    if (!openPreview) {
      saveUpdatedField(false);
    }
    // setAppInsights("Form Preview");
    dispatch(setFaqDialog(false));
    dispatch(setPreviewDialog(!openPreview));
  };

  const refreshSummary = () => {
    saveUpdatedField(true);
  };

  const largestForm = (getSignleFormDataList: any) => {
    if (
      getSignleFormDataList &&
      !!getSignleFormDataList?.flowJson &&
      getSignleFormDataList?.flowJson?.length > 1
    ) {
      const largestVersion = getSignleFormDataList.flowJson.reduce(
        (maxVersion: number, currentForm: any) =>
          currentForm.version > maxVersion ? currentForm.version : maxVersion,
        0
      );

      return getSignleFormDataList.flowJson.find(
        (form: any) => form.version === largestVersion
      );
    } else {
      return getSignleFormDataList?.flowJson || [];
    }
  };

  // useEffect(() => {
  //   if (activeGroup) {
  //     let prevGroupId = "";
  //     let nextGroupId = "";
  //     questionGroups.map((val: any, index: number) => {
  //       if (val === activeGroup) {
  //         prevGroupId = questionGroups[index - 1];
  //         nextGroupId = questionGroups[index + 1];
  //       }
  //     });

  //     if (nextGroupId != "") {
  //       const questionGroupRow: any = uiNodeList.filter(
  //         (val: any) => val.id == nextGroupId
  //       );

  //       setNextGroupButton(
  //         questionGroupRow.length
  //           ? questionGroupRow[0]?.data?.nodeTitle
  //           : "Next Group"
  //       );
  //     } else {
  //       setNextGroupButton("Next Group");
  //     }
  //     if (prevGroupId != "") {
  //       const questionGroupRow: any = uiNodeList.filter(
  //         (val: any) => val.id == prevGroupId
  //       );

  //       setPrevGroupButton(
  //         questionGroupRow.length
  //           ? questionGroupRow[0]?.data?.nodeTitle
  //           : "Prev Group"
  //       );
  //     } else {
  //       setPrevGroupButton("Prev Group");
  //     }
  //   }
  // }, [activeGroup]);

  // useEffect(() => {
  //   if (activeSummary >= 0) {
  //     let prevSummaryId = "";
  //     let nextSummaryId = "";
  //     summaryList.map((val: any, index: number) => {
  //       if (index === activeSummary) {
  //         prevSummaryId = summaryList[index - 1];
  //         nextSummaryId = summaryList[index + 1];
  //       }
  //     });

  //     if (nextSummaryId != "") {
  //       const nextSummaryArr: any = uiNodeList.filter(
  //         (val: any) => val.id == nextSummaryId
  //       );

  //       setNextSummaryButton(
  //         nextSummaryArr.length
  //           ? nextSummaryArr[0]?.data?.nodeTitle
  //           : "Next Summary"
  //       );
  //     } else {
  //       setNextSummaryButton("Next Summary");
  //     }

  //     if (prevSummaryId != "") {
  //       const prevSummaryArr: any = uiNodeList.filter(
  //         (val: any) => val.id == prevSummaryId
  //       );

  //       setPrevSummaryButton(
  //         prevSummaryArr.length ? prevSummaryArr[0]?.data?.nodeTitle : "Prev"
  //       );
  //     } else {
  //       setPrevSummaryButton("Prev");
  //     }
  //   }
  // }, [activeSummary]);

  // useEffect(() => {
  //   if (activeSection >= 0) {
  //     let prevSectionId: any = {};
  //     let nextSectionId: any = {};
  //     sectionId?.sectionList?.map((val: any, index: number) => {
  //       if (index === activeSection) {
  //         prevSectionId = sectionId?.sectionList[index - 1];
  //         nextSectionId = sectionId?.sectionList[index + 1];
  //       }
  //     });

  //     if (Object.keys(nextSectionId || {}).length) {
  //       setNextSectionButton(
  //         nextSectionId?.data?.nodeTitle
  //           ? nextSectionId?.data?.nodeTitle
  //           : "Next Section"
  //       );
  //     } else {
  //       setNextSectionButton("Next Section");
  //     }

  //     if (Object.keys(prevSectionId || {}).length) {
  //       setPrevSectionButton(
  //         prevSectionId?.data?.nodeTitle
  //           ? prevSectionId?.data?.nodeTitle
  //           : "Prev Section"
  //       );
  //     } else {
  //       setPrevSectionButton("Prev Section");
  //     }
  //   }
  // }, [activeSection, sectionId?.sectionList]);

  const saveUpdatedField = async (isRefresh: boolean = false) => {
    let env = process.env.REACT_APP_ENV;
    const updatedFormValues = JSON.parse(
      sessionStorage.getItem("updatedFormValues") || "{}"
    );

    const summaryData: any = uiNodeList?.filter(
      (val: any) =>
        summaryList?.includes(val.id) && checkDependantSummary(val, formData)
    )[activeSection];

    // Optimise API call if form has been updated then call these apis
    if (Object.keys(updatedFormValues).length !== 0 || isRefresh) {
      await dispatch(
        addUserForm({
          form_id: env === "dev" ? 1 : 1073,
          form_data: JSON.stringify(updatedFormValues),
        })
      ).then((resp: any) => {
        // dispatch(getProgressData());
        if (
          summaryData?.data?.nodeTitle.toLowerCase() == "summary" ||
          isRefresh
        ) {
          dispatch(getSummaryData());
        }

        let sessionID = sessionStorage.getItem("Usapath-Session-Id");

        let concatString: any = JSON.stringify({
          message: "uiFormPayload API called",
          sessionID: sessionID,
          UserId: bootstrapData?.id,
          RequestId: generateGuid(),
        });
        const encodedString = btoa(concatString);

        loggerRef.current?.log({
          level: "info",
          detailsJson: encodedString,
          component: "uiFormPayload API called",
        });
      });
    } else {
      if (summaryData?.data?.nodeTitle.toLowerCase() == "summary") {
        dispatch(getSummaryData());
      }
    }

    // let queList = [...currentQuestions];
    // const unSavedFormValues = JSON.parse(
    //   localStorage.getItem("unSavedFormValues") || "{}"
    // );

    // let list = queList.filter((val: any) =>
    //   checkDependantQuestion(val, unSavedFormValues)
    // );
    // const values = {
    //   FINF814: formData?.FINF814 || "",
    //   FINF815: formData?.FINF815 || "",
    //   FINF816: formData?.FINF816 || "",
    //   FINF817: formData?.FINF817 || "",
    //   FINF825: formData?.FINF825 || "",
    //   FINF826: formData?.FINF826 || "",
    //   FINF827: formData?.FINF827 || "",
    //   FINF828: formData?.FINF828 || "",
    //   FINF811: formData?.FINF811 || "",
    //   FINF812: formData?.FINF812 || "",
    //   IT381: formData?.IT381 || "",
    //   IT382: formData?.IT382 || "",
    //   IT382a: formData?.IT382a || "",
    //   PI351: formData?.PI351 || "",
    //   ...updatedFormValues,
    // };
    // const calculateIncomeObj = calculateIncomeFlag(uiIncomeLimit, values);
    // let err = ValidateFormJson({
    //   formData: { ...unSavedFormValues, ...updatedFormValues },
    //   list,
    // });

    // const filteredObj2 = Object.keys(updatedFormValues).reduce(
    //   (acc: any, key: any) => {
    //     if (!err.hasOwnProperty(key)) {
    //       acc[key] = updatedFormValues[key];
    //     }
    //     return acc;
    //   },
    //   {}
    // );
    // const updatedPayload = { ...values, ...calculateIncomeObj };
  };

  const checkDeadGroup = () => {
    let lock = sectionId?.sectionNodeData.filter(
      (val: any) =>
        val?.data?.nodeType?.toLowerCase() === "text" &&
        checkDependant(val, formData)
    );
    if (lock && lock[0]) {
      let queIds = currentQuestions.map((val: any) => val.id);
      let lockNext = false;
      if (Array.isArray(lock[0]?.data?.visibility?.condition)) {
        lock[0]?.data?.visibility?.condition?.map((val: any) => {
          if (queIds.includes(val.id)) {
            lockNext = lock[0];
          }
        });
      } else if (typeof lock[0]?.data?.visibility?.condition == "boolean") {
        return lock[0]?.data?.visibility?.condition;
      }

      return lockNext;
    }
    return false;
  };

  const setGroupList = () => {
    let result = [];
    if (typeof sectionId?.index !== "undefined") {
      let groupsArray = sectionId?.sectionNodeData.find(
        (val: any) => val.id === sectionId.summaryId
      );

      if (groupsArray && groupsArray?.children) {
        let groupList = sectionId?.sectionNodeData.filter((val: any) =>
          groupsArray?.children.includes(val.id)
        );

        if (groupList && groupList.length) {
          result = groupList
            .filter((val: any) => checkDependantGroup(val, formData))
            .map((val: any) => val.id);
        }

        const currentGroup = groupList.filter(
          (val: any) =>
            val.id == activeGroup && checkDependantGroup(val, formData)
        );

        let isContinue =
          currentGroup?.[0]?.data?.properties?.shouldContinue === false
            ? false
            : true;

        setShouldContinue(isContinue);
        setQuestionGroups(result);
      }
    }
  };

  const handleFieldChange = (id: any, value: any) => {
    if (id) {
      let unSavedValues = anonymousUser() ? unSaveData : {};
      let tempFormData = {
        ...unSavedValues,
        ...formData,
        [id]: value,
      };

      let updatedFormValues = JSON.parse(
        sessionStorage.getItem("updatedFormValues") || "{}"
      );
      updatedFormValues = {
        ...updatedFormValues,
        [id]: value,
      };

      let calculateIncomeObj = null;

      if (isIframe) {
        calculateIncomeObj = calculateIncomeFlagV2(uiIncomeLimit, {
          ...expectedCalculationFields,
          ...tempFormData,
        });
      } else {
        calculateIncomeObj = calculateIncomeFlag(uiIncomeLimit, {
          ...expectedCalculationFields,
          ...tempFormData,
        });
      }

      const calculateBonafideObj = calculateBonafideMarriageScore(tempFormData);
      let calculateRulesObj;
      calculateRulesObj = tempFormData && calculateRules(tempFormData);
      // if (
      //   decodeURI(location.pathname) === "/eligibility-check/Evidence Engine"
      // ) {
      //   calculateRulesObj = tempFormData && calculateRules(tempFormData);

      //   tempFormData = {
      //     ...tempFormData,
      //     ...calculateIncomeObj,
      //     ...calculateBonafideObj,
      //     ...calculateRulesObj,
      //   };
      //   updatedFormValues = {
      //     ...updatedFormValues,
      //     ...calculateIncomeObj,
      //     ...calculateBonafideObj,
      //     ...calculateRulesObj,
      //   };
      // } else {
      tempFormData = {
        ...tempFormData,
        ...calculateIncomeObj,
        ...calculateBonafideObj,
        ...calculateRulesObj,
      };

      updatedFormValues = {
        ...updatedFormValues,
        ...calculateIncomeObj,
        ...calculateBonafideObj,
        ...calculateRulesObj,
      };
      // }

      const defaultValueData = checkDefaultValue(id, tempFormData);

      tempFormData = {
        ...tempFormData,
        ...defaultValueData,
      };

      updatedFormValues = {
        ...updatedFormValues,
        ...defaultValueData,
      };

      // Update form data and form values with default values
      if (Object.keys(calculateIncomeObj).length > 0) {
        Object.keys(calculateIncomeObj).map((incomeObj: any) => {
          const defValues = checkDefaultValue(incomeObj, tempFormData);

          tempFormData = {
            ...tempFormData,
            ...defValues,
          };
          updatedFormValues = {
            ...updatedFormValues,
            ...defValues,
          };
        });
      }

      Object.keys(calculateBonafideObj).forEach((bonafideObj) => {
        const defValues = checkDefaultValue(bonafideObj, tempFormData);
        tempFormData = {
          ...tempFormData,
          ...defValues,
        };
        updatedFormValues = {
          ...updatedFormValues,
          ...defValues,
        };
      });

      calculateRulesObj &&
        Object.keys(calculateRulesObj).forEach((rulesObj) => {
          const defValues = checkDefaultValue(rulesObj, tempFormData);
          tempFormData = {
            ...tempFormData,
            ...defValues,
          };
          updatedFormValues = {
            ...updatedFormValues,
            ...defValues,
          };
        });

      sessionStorage.setItem(
        "updatedFormValues",
        JSON.stringify(updatedFormValues)
      );
      dispatch(setUiFormPayload(tempFormData || {}));
      setFormData(tempFormData);
    }
  };

  const checkDefaultValue = (id: any, tempFormData: any) => {
    questionList.map((row: any) => {
      if (row?.data?.properties?.defaultValue) {
        let substitutions = row?.data?.properties?.defaultValue?.substitutions;

        if (substitutions && Object.keys(substitutions || {}).length > 0) {
          Object.values(substitutions).forEach((subKeys: any) => {
            if (subKeys === id) {
              let defaultValue = checkDependantQuestionDefaultValue(
                row,
                tempFormData
              );

              tempFormData = {
                ...tempFormData,
                [row.id]: defaultValue || "",
              };

              preservValue = {
                ...preservValue,
                [row.id]: defaultValue || "",
              };

              checkDefaultValue(row.id, tempFormData); // Recursive call
            }
          });
        }

        let defaultValue = row?.data?.properties?.defaultValue?.condition;

        if (defaultValue?.length > 0) {
          const checkArr = checkDefaultId(defaultValue, id);
          const isAvailable = checkArr.some((subArray: any) =>
            Array.isArray(subArray)
              ? subArray.includes(true)
              : subArray === true
          );

          if (isAvailable) {
            let defaultValue = checkDependantQuestionDefaultValue(
              row,
              tempFormData
            );
            tempFormData = {
              ...tempFormData,
              [row.id]: defaultValue || "",
            };

            preservValue = {
              ...preservValue,
              [row.id]: defaultValue || "",
            };

            checkDefaultValue(row.id, tempFormData); // Recursive call
          }
        }
      }
    });

    return preservValue; // Return the updated value
  };

  const renderFields = (field: any) => {
    let nodeType = field?.data?.nodeType;
    let filedProp = field?.data?.properties;
    let id = field.id;
    let isShow = checkDependantQuestion(field, formData);
    let title = checkDependantQuestionTitle(field, formData);
    let editable = checkDependantQuestionEditable(field, formData);
    let defaultValue = checkDependantQuestionDefaultValue(field, formData);
    let style = field?.data?.style;
    let value = formData[id];

    if (defaultValue) {
      value = defaultValue;
      defaultValues[id] = defaultValue;
      formData = {
        ...formData,
        [id]: defaultValue,
      };

      let updatedFormValues = JSON.parse(
        sessionStorage.getItem("updatedFormValues") || "{}"
      );

      updatedFormValues = {
        ...updatedFormValues,
        [id]: value,
      };

      sessionStorage.setItem(
        "updatedFormValues",
        JSON.stringify(updatedFormValues)
      );
      // handleFieldChange(id, defaultValue);
    }

    if (!isShow) {
      return "";
    }

    if (nodeType && filedProp?.type?.toLowerCase() == "textbox" && filedProp) {
      return (
        <InputFiled
          style={style}
          title={title}
          editable={
            lockedApp === "true" ||
            sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
              ? false
              : editable
          }
          placeholder={filedProp?.properties?.placeholder || ""}
          handleFieldChange={handleFieldChange}
          id={id}
          value={value || ""}
          errors={errors || {}}
          formatting={filedProp?.properties?.formatting}
        />
      );
    }

    if (
      nodeType &&
      filedProp?.type?.toLowerCase() == "multitextbox" &&
      filedProp
    ) {
      return (
        <MultiInputField
          style={style}
          title={title}
          editable={
            lockedApp === "true" ||
            sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
              ? false
              : editable
          }
          placeholder={filedProp?.properties?.placeholder || ""}
          handleFieldChange={handleFieldChange}
          id={id}
          value={value || ""}
          errors={errors || {}}
        />
      );
    }

    if (nodeType && filedProp?.type?.toLowerCase() == "boolean" && filedProp) {
      return (
        <BooleanInput
          title={title}
          editable={
            lockedApp === "true" ||
            sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
              ? false
              : editable
          }
          value={value || false}
          handleFieldChange={handleFieldChange}
          id={id}
          errors={errors || {}}
          filedProp={filedProp}
          style={
            windowWidth < 480
              ? {
                  backgroundColor: "rgba(255,255,255,1)",
                  width: 300,
                  height: "auto",
                  border: "1px solid black",
                  borderRadius: 2,
                  fontSize: 12,
                }
              : style
          }
        />
      );
    }

    if (nodeType && filedProp?.type?.toLowerCase() == "dropdown" && filedProp) {
      return (
        <DropdownField
          style={style}
          title={title}
          editable={
            lockedApp === "true" ||
            sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
              ? false
              : editable
          }
          options={filedProp?.properties?.values || []}
          handleFieldChange={handleFieldChange}
          id={id}
          value={value || ""}
          errors={errors || {}}
        />
      );
    }

    if (filedProp?.type?.toLowerCase() == "singleselect" && filedProp) {
      return (
        <div>
          {" "}
          <MultiSelection
            style={style}
            title={title}
            editable={
              lockedApp === "true" ||
              sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
                ? false
                : editable
            }
            options={filedProp?.properties?.values || []}
            handleFieldChange={handleFieldChange}
            id={id}
            value={value || []}
            errors={errors || {}}
          />
        </div>
      );
    }

    if (filedProp?.type?.toLowerCase() == "multiselect" && filedProp) {
      return (
        <div>
          {" "}
          <MultiSelection
            style={style}
            title={title}
            multiSelect={true}
            editable={
              lockedApp === "true" ||
              sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
                ? false
                : editable
            }
            options={filedProp?.properties?.values || []}
            handleFieldChange={handleFieldChange}
            id={id}
            value={value || []}
            errors={errors || {}}
          />
        </div>
      );
    }

    let excludeFields = ["IT405", "IT411", "IT418", "IT420"];

    if (filedProp?.type?.toLowerCase() == "datetime" && filedProp) {
      return (
        <DatePickerComponent
          style={style}
          title={title}
          editable={
            lockedApp === "true" ||
            sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
              ? false
              : editable
          }
          placeholder={filedProp?.properties?.placeholder || ""}
          handleFieldChange={handleFieldChange}
          id={id}
          isMaxDate={excludeFields?.includes(id) ? true : false}
          // value={
          //   value && moment(value, "MM/DD/YYYY", true).isValid()
          //     ? new Date(value)
          //     : ""
          // }
          value={value ? value : ""}
          errors={errors || {}}
        />
      );
    }
    if (filedProp?.type?.toLowerCase() == "text" && filedProp) {
      return <TextComponent title={title} id={id} style={style} />;
    }
    if (filedProp?.type?.toLowerCase() == "listtext" && filedProp) {
      return (
        <ListTextComponent
          title={title}
          id={id}
          textList={filedProp?.properties?.textList}
          style={style}
        />
      );
    }
    if (filedProp?.type?.toLowerCase() == "formbutton" && filedProp) {
      return (
        <Button
          // loading={false}
          size="md"
          type="button"
          onPointerEnterCapture={() => {}}
          // onPointerLeaveCapture={() => {}}
          placeholder="Test"
          className="min-h-[38px] min-w-[250px] mb-[20px] text-white text-center rounded-[12px] px-[48px] bg-[#2563EB]"
          onClick={() => {
            logFunction(filedProp?.properties?.values?.link);
          }}
          style={style}
        >
          {filedProp?.properties?.values?.title}
        </Button>
      );
    }

    return field.nodeTitle;
  };

  const onClickLink = (e: any, mainId: any) => {
    e.preventDefault();
    const givenId = mainId; // Question, questionGroup, Subquestiongroup
    const nodesData = JSON.parse(getSignleFormData?.flowJson || "{}");
    const mainData = currentIdObjects(givenId, nodesData);

    const sectionLists: any = uiNodeList.filter((val: any) =>
      checkDependant(val, formData)
    );
    const activeSectionSummary = sectionLists[activeSection]?.children;
    // Find Actibe Summary index for continue and back
    const activeSummaryIndex = activeSectionSummary?.findIndex(
      (ele: any) => ele === mainData?.currentGroupSummaryId
    );

    dispatch(
      getSetSectionId({
        id: mainData?.currentSectionId || "",
        summaryId: mainData?.currentGroupSummaryId,
        questionGroupId: mainData?.currentQuestionGroupId,
        sectionNodeData: sectionId?.sectionNodeData,
        sectionList: sectionLists,
        index: activeSection,
        summaryIndex: activeSummaryIndex,
      })
    );
  };

  const onClickUpdateInfoLink = (e: any, mainId: any, indexxx: any) => {
    e.preventDefault();
    const givenId = mainId;
    const nodesData = JSON.parse(getSignleFormData?.flowJson || "{}");
    const mainData = currentIdObjects(givenId, nodesData);
    const sectionLists: any = uiNodeList?.filter((val: any) =>
      checkDependant(val, formData)
    );
    const sectionListsFlowJson: any = nodesData?.nodes?.filter((val: any) =>
      checkDependant(val, formData)
    );

    const tempData = sectionLists
      .map((uiJsonObj: any) => {
        const flowJsonObj = sectionListsFlowJson.find(
          (flowJsonObj: any) => uiJsonObj?.id === flowJsonObj?.id
        );
        if (flowJsonObj) {
          return { ...flowJsonObj, ...uiJsonObj };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    dispatch(
      getSetSectionId({
        id: mainData?.currentSectionId || "",
        summaryId: mainData?.currentGroupSummaryId,
        questionGroupId: mainData?.currentQuestionGroupId,
        sectionNodeData: sectionId?.sectionNodeData,
        // Old Logic
        // sectionList: sectionLists,
        sectionList: tempData,
        index: activeSection,
        summaryIndex: indexxx,
      })
    );
  };

  const onClickLinkSummaryIDs = (e: any, mainId: any, indexx: any) => {
    e.preventDefault();
    const givenId = mainId; // Question, questionGroup, Subquestiongroup
    const nodesData = JSON.parse(getSignleFormData?.flowJson || "{}");
    const mainData = currentIdObjectsSummary(givenId, nodesData);

    const sectionLists: any = uiNodeList.filter((val: any) =>
      checkDependant(val, formData)
    );
    const sectionListsFlowJson: any = nodesData?.nodes?.filter((val: any) =>
      checkDependant(val, formData)
    );

    const tempData = sectionLists
      .map((uiJsonObj: any) => {
        const flowJsonObj = sectionListsFlowJson.find(
          (flowJsonObj: any) => uiJsonObj?.id === flowJsonObj?.id
        );
        if (flowJsonObj) {
          return { ...flowJsonObj, ...uiJsonObj };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    dispatch(
      getSetSectionId({
        id: mainData?.currentSectionId || "",
        summaryId: mainData?.currentGroupSummaryId,
        questionGroupId: mainData?.currentQuestionGroupId,
        sectionNodeData: sectionId?.sectionNodeData,
        // Old Logic
        // sectionList: sectionLists,
        sectionList: tempData,
        index: activeSection,
        summaryIndex: indexx,
      })
    );
  };

  const renderUiForms = (
    myNodes: any,
    checkSection?: boolean,
    customStyle?: any
  ) => {
    let isMobileView = false;
    let isTabView = false;

    if (windowWidth < 480) {
      isMobileView = true;
      isTabView = true;
    } else {
      isMobileView = false;
      isTabView = false;
    }
    if (windowWidth < 992) {
      isTabView = true;
    } else {
      isTabView = false;
    }

    if (!myNodes) {
      return "";
    }
    const filteredNodes = myNodes.filter(
      (val: any) => val.id === sectionId.summaryId || checkSection
    );

    if (sectionId?.id === "Pay_and_Download") {
      return <PayAndDownload />;
    }

    const questionNodes = filteredNodes.filter(
      (val: any) => val?.data?.nodeType?.toLowerCase() == "question"
    );

    let questionGroupNode: any[] = [];

    if (questionNodes?.length) {
      const rowsQuestion = organizeQuestionsIntoRows(questionNodes);

      const node = rowsQuestion.map((val: any, rowIndex: any) => {
        let gridCount = 12;

        if (val?.length == 2) {
          gridCount = 6;
        } else if (val?.length > 2) {
          gridCount = 4;
        }
        let isShow = [true];
        if (val?.length > 0) {
          isShow = val.map((q: any) => {
            return checkDependantQuestion(q, formData);
          });
        }

        return isShow.includes(true) ? (
          <Grid
            key={rowIndex}
            item
            md={12}
            lg={12}
            container
            spacing={2}
            className="CustomFlexColumn"
            sx={{
              display: "flex",
              justifyContent: customStyle?.justify || "center",
              alignItems: "end",
              mb: "16px !important",
              alignSelf: "center",
            }}
          >
            {val.map((question: any, index: any) => {
              if (
                question?.data?.nodeType?.toLowerCase() == "question" &&
                !currentQuestions.includes(question)
              ) {
                currentQuestions.push(question);
              }

              const fullWidth =
                ["singleselect", "multiselect", "textlist", "text"].includes(
                  question?.data?.properties?.type?.toLowerCase()
                ) && gridCount == 12
                  ? true
                  : false;
              let isShow = checkDependantQuestion(question, formData);
              return isShow ? (
                <Grid
                  key={index}
                  item
                  md={gridCount}
                  className="CustomFlexColumnInner"
                  sx={{
                    justifyContent: customStyle?.justify || "center",
                    alignItems: "center",
                    display: "flex",
                    ...(!fullWidth && {
                      maxWidth: "300px !important",
                    }),
                    ...(question?.data?.style?.direction == "row" &&
                      question?.data?.nodeType?.toLowerCase() == "question" && {
                        maxWidth: "100% !important",
                      }),
                  }}
                >
                  {renderFields(question)}
                </Grid>
              ) : (
                ""
              );
            })}
          </Grid>
        ) : (
          ""
        );
      });
      if (filteredNodes.length <= questionNodes.length) {
        return node;
      }
      questionGroupNode = [...questionGroupNode, ...node];
    }

    const nodes = filteredNodes.map((val: any, nodeIndex: any) => {
      if (!tempNodes.includes(val.id)) {
        tempNodes.push(val.id);
        if (
          val?.data?.nodeType?.toLowerCase() == "sectionsummarygroup" &&
          activeSummaryId === val.id
        ) {
          if (val?.data?.nodeTitle === "Summary") {
            return (
              <div className="summaryScreen" key={nodeIndex}>
                <SummaryScreen
                  formMainData={formData}
                  mainData={val}
                  onClickLinkSummaryIDs={onClickLinkSummaryIDs}
                  onClickUpdateInfoLink={onClickUpdateInfoLink}
                  setShowRefresh={setShowRefresh}
                />
              </div>
            );
          } else {
            return (
              <>
                {renderUiForms(
                  sectionId?.sectionNodeData?.filter((row: any) =>
                    val?.children?.includes(row.id)
                  ),
                  true,
                  {}
                )}
              </>
            );
          }
        } else if (
          val.data.nodeType?.toLowerCase() == "questiongroup" &&
          activeGroup === val.id
        ) {
          const style = val?.data?.style || {};
          return val?.data?.boxLayout ? (
            <FirstScreen
              questionGroup={val}
              formData={formData}
              questionNodes={sectionId?.sectionNodeData.filter(
                (row: any) =>
                  val.children.includes(row.id) &&
                  row?.data?.nodeType?.toLowerCase() == "question"
              )}
            />
          ) : (
            <div
              id={val.id}
              className="formView"
              style={{
                padding: 2,
                paddingLeft: isTabView ? "32px" : 0,
                paddingRight: isTabView ? "32px" : 0,
                margin: "auto 0",
                marginTop: isMobileView ? "40px" : "auto",
              }}
            >
              <div
                className="w-full pb-[16px] sm:mb-[20px] lg:mb-[80px]"
                style={{ marginBottom: style?.margin }}
              >
                <h2
                  className="text-center text-[#1C2333] font-medium"
                  dangerouslySetInnerHTML={{
                    __html:
                      checkDependantQuestionTitle(val, formData) ||
                      val?.data?.nodeTitle,
                  }}
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: 700,
                  }}
                ></h2>
              </div>
              <Grid container className="flex justify-center">
                <Grid item md={12}>
                  {renderUiForms(
                    sectionId?.sectionNodeData.filter((row: any) =>
                      val.children.includes(row.id)
                    ),
                    true,
                    style
                  )}
                </Grid>
              </Grid>
            </div>
          );
        } else if (
          val?.data?.nodeType?.toLowerCase() == "subquestiongroup" &&
          checkDependantGroup(val, formData)
        ) {
          const style = val?.data?.style || {};
          return (
            <>
              <div
                className={`w-full ${val.order > 1 ? "mt-[48px]" : ""}`}
                id={val.id}
              >
                <h2
                  className="text-center text-[#1C2333] font-medium"
                  dangerouslySetInnerHTML={{
                    __html:
                      checkDependantQuestionTitle(val, formData) ||
                      val.nodeTitle,
                  }}
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: 700,
                  }}
                ></h2>
              </div>
              <Grid
                item
                md={12}
                container
                spacing={2}
                className="CustomFlexColumn"
                sx={{
                  margin: style?.margin || "0",
                  justifyContent: style?.alignment || "center",
                }}
              >
                {renderUiForms(
                  sectionId?.sectionNodeData.filter((row: any) =>
                    val.children.includes(row.id)
                  ),
                  true,
                  style
                )}
              </Grid>
            </>
          );
        }
        // else if (val?.data?.nodeType?.toLowerCase() == "evidence_1") {
        //   return (
        //     <div className="w-full md:mx-[4rem]">
        //       <Evidence1 data={val?.data} />
        //     </div>
        //   );
        // } else if (val?.data?.nodeType?.toLowerCase() == "evidence_2") {
        //   return (
        //     <div className="w-full md:mx-[4rem]">
        //       <Evidence2 data={val?.data} />
        //     </div>
        //   );
        // }
        // else if (
        //   val?.data?.nodeType?.toLowerCase() == "text" &&
        //   (sectionId?.questionGroupId === val.id || sectionId?.id === val.id)
        // ) {
        //   return (
        //     <div className="flex w-full justify-center h-full items-center flex-wrap">
        //       <BackMainPage backToMainPage={backToMainPage} />
        //     </div>
        //   );
        // }
      }
    });

    return [...questionGroupNode, ...nodes];
  };

  const handlePrev = async () => {
    scrollToElement();
    setIsHandlePrev(true);
    if (!isFirstSection) {
      if (activeSection >= 1) {
        let sectionData: any = {};
        let summaryData: any = {};
        let activeStepdt = activeSection - 1;
        const nodesData = JSON.parse(getSignleFormData?.flowJson || "{}");

        sectionData = nodesData?.nodes?.filter((val: any) =>
          checkDependant(val, formData)
        )[activeStepdt];
        // Old code
        // sectionData = uiNodeList.filter((val: any) =>
        //   checkDependant(val, formData)
        // )[activeStepdt];

        summaryData = nodesData?.nodes?.filter(
          (val: any) =>
            checkDependantGroup(val, formData) &&
            val?.parentNode == sectionData?.id &&
            val?.data?.nodeType == "sectionsummarygroup"
        );

        setCurrentQuestions([]);
        setErrors({});

        setActiveSection(activeStepdt);

        // if (
        //   summaryData[summaryData?.length - 1]?.data?.nodeTitle == "summary"
        // ) {
        //   saveUpdatedField(true);
        // } else {
        //   saveUpdatedField();
        // }

        await dispatch(
          getSetSectionId({
            id: sectionData?.id || "",
            summaryId: summaryData[summaryData?.length - 1]?.id,
            sectionNodeData: sectionId?.sectionNodeData,
            sectionList: uiNodeList.filter((val: any) =>
              checkDependant(val, formData)
            ),
            index: activeStepdt,
            summaryIndex: summaryData?.length - 1,
          })
        );
        navigate(`/eligibility-check/${sectionData?.data?.nodeTitle}`);
      }
    }
  };

  const handlePrevSummary = async () => {
    scrollToElement();
    setErrors({});
    setCurrentQuestions([]);

    if (!isFirstSummary) {
      // if (activeSection >= 1) {
      let prevSummaryId: any = "";
      let stopProcess = false;
      await summaryList?.map((val: any, index: number) => {
        if (val && activeSummaryId) {
          if (val === activeSummaryId) {
            prevSummaryId = summaryList?.[index - 1];
            if (summaryList?.[index - 1]) {
              setActiveSummaryId(summaryList?.[index - 1]);
            } else {
              stopProcess = true;
            }
          }
        }
      });

      if (stopProcess) {
        return false;
      }

      const nodesData = JSON.parse(getSignleFormData?.flowJson || "{}");

      let questionGroupData = await nodesData?.nodes?.filter(
        (val: any) =>
          checkDependantQuestion(val, formData) &&
          val?.parentNode == prevSummaryId &&
          val?.data?.nodeType?.toLowerCase() == "questiongroup"
      );
      // if (
      //   questionGroupData[
      //     questionGroupData?.length - 1
      //   ]?.data?.nodeTitle.toLowerCase() == "summary"
      // ) {
      //   saveUpdatedField(true);
      // } else {
      //   saveUpdatedField();
      // }

      dispatch(
        getSetSectionId({
          id: sectionId?.id || "",
          summaryId: prevSummaryId,
          questionGroupId: questionGroupData[questionGroupData?.length - 1]?.id,
          sectionNodeData: sectionId?.sectionNodeData,
          sectionList: uiNodeList.filter((val: any) =>
            checkDependant(val, formData)
          ),
          index: activeSection,
          summaryIndex: activeSummary - 1,
        })
      );
      // }
    }
  };

  const handlePrevGroup = async () => {
    scrollToElement();
    setErrors({});
    setCurrentQuestions([]);
    let prevGroupId: any = "";
    let tempStop: any = false;
    await questionGroups.map((val: any, index: number) => {
      if (val === activeGroup) {
        if (questionGroups[index - 1]) {
          prevGroupId = questionGroups[index - 1];
          setActiveGroup(questionGroups[index - 1]);
        } else {
          tempStop = true;
        }
      }
    });

    if (tempStop) {
      return false;
    }

    await dispatch(
      getSetSectionId({
        id: sectionId?.id || "",
        summaryId: activeSummaryId,
        questionGroupId: prevGroupId,
        sectionNodeData: sectionId?.sectionNodeData,
        sectionList: uiNodeList.filter((val: any) =>
          checkDependant(val, formData)
        ),
        index: activeSection,
        summaryIndex: activeSummary,
      })
    );
  };

  const handleNextGroup = async () => {
    scrollToElement();
    // let blockNext = checkDeadGroup();

    // if (!blockNext) {
    let queList = [...currentQuestions];
    let values = { ...defaultValues, ...formData };

    let list = queList.filter((val: any) =>
      checkDependantQuestion(val, values)
    );

    let err = ValidateFormJson({ formData: values, list, locked });

    if (Object.values(err).length === 0) {
      let activeGroupId: any = "";

      await questionGroups.map((val: any, index: number) => {
        if (val === activeGroup) {
          activeGroupId = questionGroups[index + 1];

          setActiveGroup(questionGroups[index + 1]);
        }
      });
      setCurrentQuestions([]);
      setErrors({});

      sessionStorage.setItem("unSavedFormValues", JSON.stringify(formData));
      // saveUpdatedField();

      await dispatch(
        getSetSectionId({
          id: sectionId?.id || "",
          summaryId: activeSummaryId,
          questionGroupId: activeGroupId,
          sectionNodeData: sectionId?.sectionNodeData,
          sectionList: uiNodeList?.filter((val: any) =>
            val?.data?.nodeType?.toLowerCase() == "text"
              ? checkDependantQuestion(val, values)
              : checkDependant(val, values)
          ),
          index: activeSection,
          summaryIndex: activeSummary,
        })
      );
    } else {
      setWaitForRender(false);
      setErrors(err);
    }
    // } else {
    //   setIsDeadlock(true);
    // }
  };

  const handleNextSummary = async () => {
    scrollToElement();
    let queList = [...currentQuestions];
    let values = { ...defaultValues, ...formData };
    let list = queList.filter((val: any) =>
      checkDependantQuestion(val, values)
    );

    let err = ValidateFormJson({ formData: values, list, locked });
    if (Object.values(err).length === 0) {
      setCurrentQuestions([]);
      setErrors({});

      sessionStorage.setItem("unSavedFormValues", JSON.stringify(formData));

      if (!isLastSummary) {
        let activeStepdt = activeSummary + 1;
        setActiveSummary(activeStepdt);
        const nextSummaryData: any = uiNodeList?.filter(
          (val: any) =>
            summaryList?.includes(val.id) && checkDependantSummary(val, values)
        )[activeStepdt];

        setActiveSection(activeStepdt);

        // if (nextSummaryData?.data?.nodeTitle.toLowerCase() == "summary") {
        //   saveUpdatedField(true);
        // } else {
        //   saveUpdatedField();
        // }
        dispatch(
          getSetSectionId({
            id: sectionId?.id || "",
            summaryId: nextSummaryData?.id || "",
            sectionNodeData: sectionId?.sectionNodeData,
            sectionList: uiNodeList.filter((val: any) =>
              val?.data?.nodeType?.toLowerCase() === "text"
                ? checkDependantQuestion(val, values)
                : checkDependant(val, values)
            ),
            index: activeSection,
            summaryIndex: activeStepdt,
          })
        );
      }
    } else {
      setErrors(err);
      setWaitForRender(false);
    }
  };

  const handleNext = async () => {
    scrollToElement();
    setIsHandlePrev(true);
    let queList = [...currentQuestions];
    let values = { ...defaultValues, ...formData };

    let list = queList?.filter((val: any) =>
      checkDependantQuestion(val, values)
    );

    let err = ValidateFormJson({ formData: values, list, locked });

    if (Object.values(err).length === 0) {
      setCurrentQuestions([]);
      setErrors({});
      sessionStorage.setItem("unSavedFormValues", JSON.stringify(formData));
      if (!isLastSection) {
        let data: any = {};
        let activeStepdt = activeSection + 1;

        setActiveSection(activeStepdt);

        data = uiNodeList?.filter((val: any) => checkDependant(val, formData))[
          activeStepdt
        ];
        const nextSummary = await sectionId?.sectionNodeData?.filter(
          (val: any) => val.id == data?.id
        )[0]?.children;

        setSummaryList(nextSummary);
        setIsHandlePrev(false);

        navigate(`/eligibility-check/${data?.data?.nodeTitle}`);

        dispatch(
          getSetSectionId({
            id: data?.id || "",
            summaryId: nextSummary?.[0] || "",
            questionGroupId: "",
            sectionNodeData: sectionId?.sectionNodeData,
            sectionList: uiNodeList?.filter((val: any) =>
              checkDependant(val, values)
            ),
            index: activeStepdt,
            summaryIndex: 0,
          })
        );
      }
    } else {
      setErrors(err);
      setWaitForRender(false);
    }
  };

  const submitForm = () => {
    scrollToElement();
    let queList = [...currentQuestions];
    let values = { ...defaultValues, ...formData };
    let list = queList.filter((val: any) =>
      checkDependantQuestion(val, values)
    );

    let err = ValidateFormJson({ formData: values, list, locked });

    if (Object.values(err).length === 0) {
      // saveUpdatedField();
      // dispatch(
      //   addUserForm({
      //     form_id: 1073,
      //     form_data: JSON.stringify(values),
      //     id: null,
      //     form_completed: true,
      //   })
      // );
      // dispatch(getSetSectionId({}));
      // localStorage.removeItem("unSavedFormValues");
      // dispatch(setCurrentFormData({}));
      // navigate("/upload-document"); {/* Open if Uplaod document feature open on navbar */}
    } else {
      setErrors(err);
      setWaitForRender(false);
    }
    setWaitForRender(false);
  };

  const handleClearAll = () => {
    let tempFormData = { ...formData };
    let updatedFormValues = JSON.parse(
      sessionStorage.getItem("updatedFormValues") || "{}"
    );

    // setAppInsights("Button - Clear All");

    currentQuestions.map((val: any) => {
      tempFormData[val.id] = "";
      updatedFormValues[val.id] = "";
      questionList.map((row: any) => {
        if (row?.data?.properties?.defaultValue) {
          let substitutions =
            row?.data?.properties?.defaultValue?.substitutions;

          if (substitutions && Object.keys(substitutions || {}).length > 0) {
            Object.values(substitutions).map((subKeys: any) => {
              if (subKeys === val.id) {
                let defaultValue = checkDependantQuestionDefaultValue(
                  row,
                  tempFormData
                );
                tempFormData = {
                  ...tempFormData,
                  [row.id]: defaultValue || "",
                };

                updatedFormValues = {
                  ...updatedFormValues,
                  [row.id]: defaultValue || "",
                };
              }
            });
          }
        }
      });
    });
    sessionStorage.setItem(
      "updatedFormValues",
      JSON.stringify(updatedFormValues)
    );
    dispatch(setUiFormPayload(tempFormData) || "{}");
    setFormData(tempFormData);
  };

  function scrollToElement() {
    const scrollElement = document.getElementById("scrollElement");
    if (scrollElement) {
      scrollElement.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for instant scrolling
      });
    }
  }

  const clickContinueFunc = () => {
    if (!shouldContinue) {
      return false;
    }

    clickCount = clickCount + 1;
    setdebounceCount(clickCount);
    setWaitForRender(true);

    let sessionID = sessionStorage.getItem("Usapath-Session-Id");

    let concatString: any = JSON.stringify({
      message: "Continue pressed",
      sessionID: sessionID,
      UserId: bootstrapData?.id,
      QuestionGroup: questionGroups,
    });
    const encodedString = btoa(concatString);

    loggerRef.current?.log({
      level: "info",
      detailsJson: encodedString,
      component: "Continue Button",
    });

    if (
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups[questionGroups.length - 1]
    ) {
      handleNextGroup();
    } else if (!isLastSummary) {
      handleNextSummary();
    } else {
      handleNext();
    }
  };

  const handleContinue = () => {
    clickContinueFunc();
  };

  useEffect(() => {
    const saveData = setTimeout(async () => {
      if (clickCount === debunceCount && !isHandlePrev) {
        await saveUpdatedField(false);
      }
    }, 500);

    return () => {
      setIsHandlePrev(false);
      clearTimeout(saveData);
    };
  }, [debunceCount]);

  const clickBackButtonFunc = () => {
    clickCount = clickCount + 1;
    setdebounceCount(clickCount);
    setWaitForRender(true);

    let sessionID = sessionStorage.getItem("Usapath-Session-Id");

    let concatString: any = JSON.stringify({
      message: "Back pressed",
      sessionID: sessionID,
      UserId: bootstrapData?.id,
      QuestionGroup: questionGroups,
    });
    const encodedString = btoa(concatString);

    loggerRef.current?.log({
      level: "info",
      detailsJson: encodedString,
      component: "Back Button",
    });

    if (
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups?.[0]
    ) {
      handlePrevGroup();
    } else if (!isFirstSummary) {
      handlePrevSummary();
    } else if (!isFirstSection) {
      handlePrev();
    }
  };

  const renderBackButtons = () => {
    const handleClickButton = async () => {
      clickBackButtonFunc();
    };

    let extraClassName =
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups?.[0]
        ? "prevQuestionGroup"
        : !isFirstSummary
        ? "prevSummaryGroup"
        : !isFirstSection
        ? "prevSection"
        : "";

    let buttonText =
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups?.[0]
        ? "Back"
        : !isFirstSummary
        ? "Back"
        : !isFirstSection
        ? "Back"
        : "";

    let disabledButton =
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups?.[0]
        ? undefined
        : !isFirstSummary
        ? isFirstSummary
        : !isFirstSection
        ? isFirstSection
        : undefined;

    return (
      <>
        {buttonText ? (
          <Button
            // loading={false}
            size="md"
            type="button"
            onPointerEnterCapture={() => {}}
            // onPointerLeaveCapture={() => {}}
            disabled={disabledButton || waitForRender}
            placeholder="Test"
            onClick={() => handleClickButton()}
            className={`bg-[#F9FAFB] text-[#2563EB] items-center p-3 rounded-[100px] capitalize flex shadow-none border-blue-600 border-2 border-solid prevQuestionGroup ${extraClassName}`}
          >
            <img src={ArrowLeft} alt="ArrowLeft" />
            <span className="ml-[10px] sm:text-[11px] md:text-sm">
              {buttonText}
            </span>
          </Button>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderContinueButtons = () => {
    let extraClassName =
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups[questionGroups.length - 1]
        ? "nextQuestionGroup"
        : !isLastSummary
        ? "nextSummaryGroup"
        : isLastSection
        ? "completeForm"
        : "nextSection";

    const handleFunc = () => {
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups[questionGroups.length - 1]
        ? handleContinue()
        : !isLastSummary
        ? handleContinue()
        : isLastSection
        ? submitForm()
        : handleContinue();
    };

    let buttonText =
      questionGroups &&
      questionGroups.length > 1 &&
      activeGroup !== questionGroups[questionGroups.length - 1]
        ? "Continue"
        : !isLastSummary
        ? "Continue"
        : isLastSection
        ? "Continue"
        : "Continue";

    return (
      <Button
        // loading={false}
        size="md"
        type="button"
        disabled={waitForRender}
        onPointerEnterCapture={() => {}}
        // onPointerLeaveCapture={() => {}}
        placeholder="Test"
        onClick={handleFunc}
        className={`bg-[#2563EB] items-center p-3 shadow-none rounded-[100px] capitalize flex ${extraClassName}`}
      >
        <span className="mr-[10px] sm:text-[11px] md:text-sm">
          {buttonText}
        </span>
        <img src={ArrowRight} alt="ArrowRight" />
      </Button>
    );
  };

  const headerElement = document.getElementsByTagName("header");
  const bannerElement = document.getElementsByClassName("banner-height");

  const headerHeight = bannerElement
    ? bannerElement[0]?.clientHeight + headerElement[0]?.clientHeight
    : headerElement[0]?.clientHeight || 0;

  return (
    <>
      <div
        className={`h-full InnerForm`}
        style={{ height: `calc(100vh - ${headerHeight + 85 + 89}px)` }}
      >
        {windowWidth < 767 ? (
          <Box
            sx={{
              position: "absolute",
              right: "0",
              zIndex: "2",
              margin: "20px 32px",
            }}
          >
            {showRefresh && (
              <Tooltip title="Refresh">
                <IconButton
                  size="medium"
                  onClick={() => refreshSummary()}
                  sx={{ background: "#2563eb4a", marginRight: "10px" }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
            {bootstrapData?.userPersona?.toLowerCase() !== "client" && (
              <>
                <Tooltip title="FAQs">
                  <IconButton
                    size="large"
                    onClick={toggleFaqDrawer}
                    sx={{ background: "#2563eb4a", marginRight: "10px" }}
                  >
                    <MessageIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Preview Form">
                  <IconButton
                    size="large"
                    onClick={togglePreviewDrawer}
                    sx={{ background: "#16a34a33" }}
                  >
                    <EyeIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        ) : (
          <>
            {userRole !== "client" && (
              <div
                onClick={togglePreviewDrawer}
                className={`z-[900] cursor-pointer mt-5 absolute right-[30px] overflow-hidden text-green-600 flex items-center justify-center float-right shadow-none rounded-[40px] bg-[#F0FDF4] py-[8px] px-[20px]`}
              >
                <span style={{ marginRight: "6px" }}>
                  <EyeIcon />
                </span>
                Preview Form
              </div>
            )}
            {window.self === window.top && (
              <div
                onClick={toggleFaqDrawer}
                className={`z-[900] cursor-pointer mt-5 absolute right-[210px] overflow-hidden text-blue-600 flex items-center justify-center float-right shadow-none rounded-[40px] bg-[#EFF6FF] py-[8px] px-[20px]`}
              >
                <span style={{ marginRight: "6px" }}>
                  <MessageIcon />
                </span>
                FAQs
              </div>
            )}
            {showRefresh && (
              <div
                onClick={() => refreshSummary()}
                className={`z-[900] cursor-pointer mt-5 absolute right-[325px] overflow-hidden text-blue-600 flex items-center justify-center float-right shadow-none rounded-[40px] bg-[#EFF6FF] py-[8px] px-[20px]`}
              >
                <span style={{ marginRight: "6px" }}>
                  <RefreshIcon />
                </span>
                Refresh
              </div>
            )}
          </>
        )}

        <div className="stepper-wrapper h-full">
          <div className="w-full h-full overflow-y-auto">
            <Stepper
              placeholder="Test"
              activeStep={activeSection}
              isLastStep={(value) => setIsLastSection(value)}
              isFirstStep={(value) => {
                setIsFirstSection(value);
              }}
              className="grid grid-cols-3 gap-10 hidden"
              onPointerEnterCapture={() => {}}
              // onPointerLeaveCapture={() => {}}
            >
              {uiNodeList
                .filter((val: any) => checkDependant(val, formData))
                .map((section: any, index: any) => (
                  <div key={index}>
                    <p> {section.id}</p>
                    <Step
                      placeholder="Test"
                      key={index}
                      id={section?.id}
                      onClick={() => {
                        setActiveSection(index);
                      }}
                      onPointerEnterCapture={() => {}}
                      // onPointerLeaveCapture={() => {}}
                    />
                  </div>
                ))}
            </Stepper>
            <Stepper
              placeholder="Test"
              activeStep={activeSummary}
              isLastStep={(value) => setIsLastSummary(value)}
              isFirstStep={(value) => {
                setIsFirstSummary(value);
              }}
              className="grid grid-cols-3 gap-10 hidden"
              onPointerEnterCapture={() => {}}
              // onPointerLeaveCapture={() => {}}
            >
              {uiNodeList
                .filter(
                  (val: any) =>
                    summaryList?.includes(val.id) &&
                    checkDependantSummary(val, formData)
                )
                .map((summary: any, index: any) => (
                  <div key={index}>
                    <p> {summary.id}</p>
                    <Step
                      placeholder="Test"
                      key={index}
                      id={summary?.id}
                      onClick={() => {
                        setActiveSummary(index);
                      }}
                      onPointerEnterCapture={() => {}}
                      // onPointerLeaveCapture={() => {}}
                    />
                  </div>
                ))}
            </Stepper>

            <div
              className="w-full h-full flex justify-center items-center"
              style={{ alignItems: "stretch" }}
            >
              <div
                className="w-full"
                id="scrollElement"
                style={{
                  display: activeSection === sectionId?.index ? "flex" : "none",
                  paddingTop: windowWidth < 767 ? "20px" : "70px",
                  paddingBottom: hidden ? "70px" : "170px",
                  height: "100%",
                }}
              >
                {renderUiForms(sectionId?.sectionNodeData, false, {})}
              </div>
            </div>

            {/* {isClickedYes == false && activeSection == 5 && (
              <div className="text-white flex items-center justify-center h-[60vh] w-[565px] mx-auto my-0">
                <BackMainPage
                  setActiveSection={setActiveSection}
                  setIsClickedYes={setIsClickedYes}
                />
              </div>
            )} */}
          </div>
        </div>
        <div
          style={{ height: windowWidth > 767 ? "85px" : "65px" }}
          className={`fixed bottom-0 left-0 w-full flex items-center  justify-between border-t border-solid border-[#E5E7EB] bg-[white] ${
            (openFAQ || openPreview) && hidden
              ? "closeFooterWdith"
              : "openFooterWdith"
          }`}
        >
          <Button
            // loading={false}
            size="md"
            type="button"
            onPointerEnterCapture={() => {}}
            // onPointerLeaveCapture={() => {}}
            placeholder="Test"
            onClick={handleClearAll}
            className={`bg-[#FF5733] items-center p-3 rounded-[100px] capitalize flex mr-2 `}
          >
            <span className="mr-[10px] sm:text-[11px] md:text-sm ">
              Clear Page
            </span>
          </Button>

          <div className="flex">
            {sectionId?.id === "Pay_and_Download" ? (
              <>
                <Button
                  // loading={false}
                  size="md"
                  type="button"
                  onPointerEnterCapture={() => {}}
                  // onPointerLeaveCapture={() => {}}
                  placeholder="Test"
                  onClick={handlePrev}
                  disabled={isFirstSection}
                  className="bg-[#F9FAFB] text-[#2563EB] items-center p-3 rounded-[100px] capitalize flex shadow-none border-blue-600 border-2 border-solid prevSection"
                >
                  <img src={ArrowLeft} alt="ArrowLeft" />
                  <span className="ml-[10px] sm:text-[11px] md:text-sm">
                    {" "}
                    Back
                  </span>
                </Button>
              </>
            ) : (
              renderBackButtons()
            )}

            {shouldContinue && (
              <div className="ml-3">
                {sectionId?.id === "Pay_and_Download" ? (
                  <>
                    <Button
                      size="md"
                      type="button"
                      onPointerEnterCapture={() => {}}
                      // onPointerLeaveCapture={() => {}}
                      // loading={loading}
                      placeholder="Test"
                      onClick={() => {
                        !bootstrapData?.paymentCompleted
                          ? dispatch(
                              notificationFail(
                                "Please make sure you've completed payment."
                              )
                            )
                          : isLastSection
                          ? submitForm()
                          : handleNext();
                      }}
                      className={`bg-[#2563EB] items-center p-3 shadow-none rounded-[100px] capitalize flex ${
                        isLastSection ? "completeForm" : "nextSection"
                      }`}
                    >
                      <span className="mr-[10px] sm:text-[11px] md:text-sm">
                        {"Continue"}
                      </span>
                      <img src={ArrowRight} alt="ArrowRight" />
                    </Button>
                  </>
                ) : (
                  <>{renderContinueButtons()}</>
                )}
              </div>
            )}
          </div>
        </div>

        {/* <div className="bottom-[2%] absolute right-[25px] text-blue-600 flex justify-center float-right shadow-none rounded-[40px] bg-[#f9fafb]">
        <span className="absolute w-full text-xs z-0 -top-3 bg-[#fff]">
          Need help?
        </span>
        <img src={UserIcon} alt="UserIcon" />
      </div> */}
      </div>
    </>
  );
}

export default StepperComponent;
